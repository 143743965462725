import * as React from "react";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo";
import {StaticImage} from "gatsby-plugin-image";

import * as styles from "./sluzby.module.scss";

const ServicesPageForFirms = () => {
  return (
    <Layout>
      <StaticImage src="../../images/building_sky.jpg"
                   alt="Ilustrace v pozadí"
                   className={styles.background}
                   loading={"eager"}
                   objectPosition={"90% 50%"}
      />
      <Seo title="Služby" />
      <section className={styles.intro}>
        <h1>Inženýring stavebních projektů</h1>
        <p>Řešíme zdánlivě neřešitelná zadání a jsme prostředníkem mezi stavebníky a státní správou. Pomáháme tak najít optimální cestu k realizaci jakéhokoliv stavebního záměru.</p>
      </section>
      <section className={styles.servicesSection}>
        <h2>Nabízíme služby v těchto oblastech:</h2>
        <div className={styles.services}>
          <div className={styles.service}>
            <h3 className={styles.title}>Územní a stavení řízení</h3>
            <p className={styles.description}>
              Umístění a povolení staveb všech druhů, tj. rodinné a bytové domy, průmyslové objekty, stavby občanské vybavenosti.
            </p>
          </div>
          <div className={styles.service}>
            <h3 className={styles.title}>Změny užívání staveb</h3>
            <p className={styles.description}>
              Změny v užívání různých typů staveb. Změny stavby před dokončením.
            </p>
          </div>
          <div className={styles.service}>
            <h3 className={styles.title}>Dopravní a technická infrastruktura</h3>
            <p className={styles.description}>
              Vodohospodářské stavby  - vodovody, kanalizace aj. <b/>Pozemní a místní komunikace.
            </p>
          </div>
          <div className={styles.service}>
            <h3 className={styles.title}>Land development management</h3>
            <p className={styles.description}>
              Kompletní služby při povolení dělení větších celků pozemků na stavební parcely. Povolení související dopravní a technické infrastruktury. Smluvní dokumenty.
            </p>
          </div>
          <div className={styles.service}>
            <h3 className={styles.title}>územní plánování</h3>
            <p className={styles.description}>
              Změny funkčního využití pozemků.<b/> Zhodnocení možnosti výstavby.
            </p>
          </div>
          <div className={styles.service}>
            <h3 className={styles.title}>Konzultace</h3>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ServicesPageForFirms;
